export function initForm() {
    const modalContent = document.getElementById('modal-content');
    modalContent.addEventListener( 'wpcf7mailsent', function( e ) {
        modalContent.innerHTML = `
         <div class="success-checkmark">
            <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
            </div>
        </div>
        <h2>Herzlichen Dank für Ihre Anfrage!</h2>
        <p class="text-center">Wir melden uns so schnell wie möglich mit einer Antwort retour!</p>
        <div class="col text-center">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
        </div>
        `;
    }, false );

    const contactForm = document.getElementsByClassName('contact-form__form');

    for (let form of contactForm) {
        form.addEventListener('wpcf7mailsent', (e) => {
            form.innerHTML = `
            <div class="success-checkmark">
            <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
            </div>
        </div>
        <h2 class="text-white">Herzlichen Dank für Ihre Anfrage!</h2>
        <p class="text-center text-white">Wir melden uns so schnell wie möglich mit einer Antwort retour!</p>
            `;
        })
    }

}
