import Splide from '@splidejs/splide';

const splides = document.getElementsByClassName('splide');

if( splides.length ){
    for(const splide of splides) {
        new Splide(splide, {
            type: 'loop',
            perPage: 3,

            breakpoints: {
                1200: {
                    perPage: 3,
                },
                992: {
                    perPage: 2,
                }
            }
        }).mount();
    }
}
