// You can specify which plugins you need
import {Tooltip, Toast, Popover, Modal} from 'bootstrap';

import './mmenu/index.js';
import './splide/index.js';
import './headerScroll/index.js';
import './clickNavigation/index.js';
import {initForm} from "./cf7";


if (document.readyState === 'loading') {  // Loading hasn't finished yet
    document.addEventListener('DOMContentLoaded', afterDomContentIsReady);
} else {  // `DOMContentLoaded` has already fired
    afterDomContentIsReady();
}

function afterDomContentIsReady() {
    initForm();
    const modalBtns = document.querySelectorAll('.bs-modal-btn');
    const modal = new Modal(document.getElementById('lead-modal'))
    modalBtns.forEach(btn => {
        btn.addEventListener('click', (e) => {
            e.preventDefault();
            modal.show();
            wpcf7.init(document.querySelector('.wpcf7-form'));
            wpcf7cf.initForm(jQuery('.wpcf7-form'));
        });
    });
}





