import 'mmenu-js';


const menu = document.getElementById('menu');
const mobileBtn = document.getElementsByClassName('menu');
if (menu) {

    menu.classList.remove('d-none');

    const mmenu = new Mmenu("#menu", {
            "extensions": [
                "pagedim-black",
                "position-right"
            ],
            "title": "Wötzer Kassen-Center Tirol",
            "iconPanels": false,
            "navbars": [{
                "position": "bottom",
                "content": [
                    "<p>Wötzer Kassen-Center Tirol</p>"
                ]
            }],
        }, //config
        {
            offCanvas: {
                page: {
                    selector: '#woetzer-page'
                }
            }
        }
    );

    const api = mmenu.API;

    console.log(menu);

    api.bind('close:after', function () {
        mobileBtn[0].classList.remove('opened');
    });


}
